<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-media class="d-flex flex-column" no-body>
        <b-media-aside>
          <b-link>
            <b-img
              ref="previewEl"
              rounded
              :src="
                profile_image && profile_image !== null
                  ? profile_image
                  : 'https://aircraft.wpenginepowered.com/wp-content/uploads/2016/09/no-profile-img.jpg'
              "
              height="80"
            />
          </b-link>
        </b-media-aside>
        <b-media-body class="mt-75 ml-75">
          <b-button
            variant="primary"
            size="sm"
            class="mb-75 mr-75"
            @click="handleClickProfileImage"
          >
            Upload
            <div style="display: none">
              <b-form-file
                v-model="profile_image"
                id="fileUpload"
                accept="image/*"
                @input="handleFile($event, 'profile', 'farmer')"
              />
            </div>
          </b-button>
          <b-button
            variant="outline-secondary"
            size="sm"
            class="mb-75 mr-75"
            @click="removeprofileImage"
          >
            Reset
          </b-button>
        </b-media-body>
      </b-media>
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col md="4">
            <b-form-group>
              <label>Surname</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="surname Name"
              >
                <b-form-input v-model="surname" placeholder="Enter Surname" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Farmer Name</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Farmer Name"
              >
                <b-form-input v-model="name" placeholder="Enter Farmer Name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Father/Husband Name</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Father/Husaband Name"
              >
                <b-form-input
                  v-model="fathername"
                  placeholder="Enter Father/Husband Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Farmer Mobile No</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required|digits:10"
                name="Mobile No"
              >
                <b-form-input
                  v-model="mobile"
                  maxlength="10"
                  placeholder="Enter Mobile No"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label> Farmer Email</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required|email"
                name="Email"
              >
                <b-form-input v-model="email" placeholder="Enter Email" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Address 1"
              >
                <label>Address 1</label>
                <label style="color: red !important">*</label>
                <b-form-input
                  v-model="addressline1"
                  placeholder="Enter Address 1"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Address 2"
              >
                <label>Address 2</label>
                <label style="color: red !important">*</label>
                <b-form-input
                  v-model="addressline2"
                  placeholder="Enter Address 2"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Address 3"
              >
                <label>Address 3</label>
                <label style="color: red !important">*</label>
                <b-form-input
                  v-model="addressline3"
                  placeholder="Enter Address 3"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Pin Code</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required|digits:6"
                name="Pin Code"
              >
                <b-form-input
                  v-model="pincode"
                  maxlength="6"
                  @input="clickPincode($event)"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  type="number"
                  placeholder="Enter Pin Code"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>City</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="City"
              >
                <v-select
                  v-model="city"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="cityOption"
                  label="City"
                  @input="clickArea($event)"
                  placeholder="None"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Area</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Area"
              >
                <v-select
                  v-model="area"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="areaOption"
                  placeholder="None"
                  @input="handlePincode()"
                  label="PostOfficeName"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>State</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="State"
              >
                <v-select
                  v-model="state"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="None"
                  :options="stateOptions"
                  label="State"
                  @input="handleCity()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Gender</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Password"
              >
                <v-select
                  v-model="gender"
                  placeholder="None"
                  :options="['Male', 'Female']"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Farmer Aadhar card No</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Aadhar No"
              >
                <b-form-input
                  v-model="aadharcardno"
                  maxlength="14"
                  @input="validateAadharClick($event)"
                  placeholder="Enter Aadhar No"
                />
                <small
                  v-if="farmeraadharnoValidate == false"
                  class="text-danger"
                  >Enter Valid Aadhar No</small
                ><small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Pan No</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Pan No"
              >
                <b-form-input
                  v-model="panno"
                  maxlength="10"
                  @input="validatePanClick($event)"
                  placeholder="Enter PAN No"
                />
                <small v-if="pannoValidate == false" class="text-danger"
                  >Enter Valid PAN No</small
                >
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Aadhar Card Front Side</label>
              <b-form-file
                v-model="documents.aadharfrontside"
                placeholder="Select Photo"
                drop-placeholder="Drop file here..."
                accept="image/*"
                @input="handleFile($event, 'aadharfrontside', 'farmer')"
              />
            </b-form-group>
            <attachment :data="documents.aadharfrontside" />
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Aadhar Card Back Side</label>
              <b-form-file
                v-model="documents.aadharbackside"
                placeholder="Select Photo"
                drop-placeholder="Drop file here..."
                accept="image/*"
                @input="handleFile($event, 'aadharbackside', 'farmer')"
              />
            </b-form-group>
            <attachment :data="documents.aadharbackside" />
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Pan Card</label>
              <b-form-file
                v-model="documents.pancard"
                placeholder="Select Photo"
                drop-placeholder="Drop file here..."
                accept="image/*"
                @input="handleFile($event, 'pancard', 'farmer')"
              />
            </b-form-group>
            <attachment :data="documents.pancard" />
          </b-col>
          <!-- <b-col md="4">
            <b-form-group>
              <label>Photo</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Photo"
              >
                <b-form-file
                  v-model="farmerphoto"
                  placeholder="Select Document"
                  drop-placeholder="Drop file here..."
                  @input="handleChangeFile($event, 'farmer')"
                  accept="image/*"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <attachment :data="farmerphoto" />
          </b-col> -->
          <b-col md="12" class="mt-1">
            <div style="width: 100%; overflow: auto">
              <table class="table table-bordered" style="width: 28.5cm">
                <thead class="text">
                  <tr>
                    <th>Sr. No</th>
                    <th>Document Name</th>
                    <th>Document Attachment</th>
                    <th>Remarks</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in data" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>
                      <b-form-input
                        v-model="item.documentname"
                        placeholder="Enter Document Name"
                      />
                    </td>
                    <td>
                      <b-form-file
                        v-model="item.documentattached"
                        placeholder="Select Document"
                        drop-placeholder="Drop file here..."
                        @input="handleFile($event, index, 'farmer')"
                        accept="image/*"
                      />
                      <attachment :data="item.documentattached" />
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.remarks"
                        placeholder="Enter Remarks"
                      />
                    </td>
                    <td>
                      <b-button
                        variant="outline-danger"
                        class="mt-0"
                        @click="removeRow(index)"
                      >
                        <feather-icon icon="TrashIcon" class="" />
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-col>
          <b-button variant="primary" @click="addRow" class="mt-1 ml-1 mb-1">
            <feather-icon icon="PlusIcon" class="mr-25" />
            <span>Add More</span>
          </b-button>
          <!-- submit button -->
          <b-col cols="12" class="mt-1">
            <b-button
              variant="primary"
              :disabled="flag || submitDisable"
              type="submit"
              @click.prevent="submitForm"
              class="mr-4"
            >
              Submit
            </b-button>
            <b-button variant="primary" @click="onClickBack">Back </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!-- <template #code>
      {{ codeType }}
    </template> -->
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import {
  BFormInput,
  BFormFile,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import axios from '@/components/axios';
import Attachment from "../../../../components/Attechment.vue";

export default {
  components: {
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
    flatPickr,
    PinchScrollZoom,
    Datepicker,
    BFormFile,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    Attachment,
  },
  data() {
    return {
      surname: "",
      name: "",
      fathername: "",
      mobile: "",
      email: "",
      addressline1: "",
      addressline2: "",
      addressline3: "",
      city: "",
      cityOption: [],
      pincode: "",
      state: "",
      gender: "",
      aadharcardno: "",
      farmeraadharnoValidate: true,
      profile_image: "",
      data: [
        {
          documentname: "",
          documentattached: "",
          remarks: "",
        },
      ],
      flag: false,
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      ifEdit: false,
      fromState: "",
      area: "",
      areaOption: [],
      StateData: [],
      stateOptions: [],
      submitDisable: false,
      panno: "",
      documents: {
        aadharfrontside: "",
        aadharbackside: "",
        pancard: "",
      },
      pannoValidate: true,
    };
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Farmer") {
          if (this.$route.params.id) {
            if (item.edit !== 1) {
              this.$router.push("/master/accounting/farmer");
            }
          } else if (item.add !== 1) {
            this.$router.push("/master/accounting/farmer");
          }
        }
      });
    }
    this.getState();
    // this.getEmployee()
    this.ifEdit = !!this.$route.params.id;
    this.ifEdit && this.getEditValue();
  },
  methods: {
    handleClickProfileImage() {
      document.getElementById("fileUpload").click();
    },
    removeprofileImage() {
      this.profile_image = null;
    },
    addRow() {
      this.data.push({
        documentname: "",
        documentattached: "",
        remarks: "",
      });
    },
    removeRow(index) {
      this.data.splice(index, 1);
    },
    async handleFile(e, name, type) {
      this.flag = true;
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const formData = new FormData();
      formData.append("image", e);
      formData.append("type", type);

      const requestoption = {
        method: "POST",
        headers: {
          "Contant-type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
        data: formData,
        url: `${baseApi}/attachment`,
      };
      await axios(requestoption)
        .then((response) => {
          if (isNaN(name)) {
            if (name == "profile") {
              this.profile_image =  process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
              return;
            }
            this.documents[name] =  process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
          } else {
            this.data[name].documentattached =  process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
          }
          this.flag = false;
        })
        .catch((error) => console.log(error, "error"));
    },

    validatePanClick(e) {
      const regex = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);
      this.panno = this.panno.toUpperCase();
      if (regex.test(this.panno) == true) {
        this.pannoValidate = true;
      } else {
        this.pannoValidate = false;
      }
    },
    async getEditValue() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getfarmerById/${this.$route.params.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.getEditItems(json.data.data[0]);
        })
        .catch((error) => console.log(error, "error"));
    },
    getEditItems(data) {
      this.surname = data.surname;
      this.name = data.name;
      this.fathername = data.fathername;
      this.mobile = data.mobile;
      this.email = data.email;
      this.addressline1 = data.addressline1;
      this.addressline2 = data.addressline1;
      this.addressline3 = data.addressline1;
      this.city = data.city;
      this.area = data.area;
      this.pincode = data.pincode;
      this.state = data.state;
      this.gender = data.gender;
      this.aadharcardno = data.aadharcardno;
      this.profile_image = data.profile_image;
      this.data = data.FarmerDocuments;
      this.documents.aadharfrontside = data.aadharfrontside;
      this.documents.aadharbackside = data.aadharbackside;
      this.documents.pancard = data.pancard;
      this.panno = data.panno;
      // this.clickPincode()
      this.handleCity();
      this.handleArea();
    },
    async handleArea() {
      const reportauthority = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getAreaByCity`,
        data: {
          City: this.city,
        },
      };
      await axios(reportauthority)
        .then((response) => {
          this.areaOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    clickArea(e) {
      if (this.fromState == true) {
        this.handleArea();
      }

      //     this.areaOption = []
      //   this.StateData.map((item) => {
      //     if (item.City == e) {
      //       this.areaOption.push(item.PostOfficeName)
      //     }
      //   })
    },
    handlePincode() {
      if (this.fromState == true) {
        this.pincode = this.area.Pincode;
      }
    },
    async handleCity() {
      this.cityOption = [];
      this.areaOption = [];
      this.fromState = true;
      const reportauthority = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getCityByState`,
        data: {
          State: this.state,
        },
      };
      await axios(reportauthority)
        .then((response) => {
          this.cityOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async getState() {
      const reportauthority = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getState`,
      };
      await axios(reportauthority)
        .then((response) => {
          this.stateOptions = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },

    async clickPincode() {
      this.area = "";
      this.city = "";
      this.state = "";
      this.cityOption = [];
      this.areaOption = [];
      const regex = new RegExp(/^[0-9]{6}$/);
      if (regex.test(this.pincode)) {
        this.fromState = false;
        const reportauthority = {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          url: `${this.baseApi}/pincode`,
          data: {
            Pincode: `${this.pincode}`,
          },
        };
        await axios(reportauthority)
          .then((response) => {
            this.cityOption = [];
            this.StateData = response.data.data;
            this.areaOption = response.data.data;
            this.state = response.data.data[0];
            const resArr = [];
            response.data.data.filter(function (item) {
              const i = resArr.findIndex((x) => x.City == item.City);
              if (i <= -1) {
                resArr.push(item);
              }
              return null;
            });
            this.cityOption = resArr;
          })
          .catch((error) => console.log(error, "error"));
      }
    },
    validateAadharClick(e) {
      const regex = new RegExp(/^[2-9]{1}[0-9]{3}\s{1}[0-9]{4}\s{1}[0-9]{4}$/);
      this.aadharcardno.length == 4
        ? (this.aadharcardno = this.aadharcardno.concat(" "))
        : this.aadharcardno;
      this.aadharcardno.length == 9
        ? (this.aadharcardno = this.aadharcardno.concat(" "))
        : this.aadharcardno;
      if (regex.test(this.aadharcardno) == true) {
        this.farmeraadharnoValidate = true;
      } else {
        this.farmeraadharnoValidate = false;
      }
    },
    submitForm(e) {
      const data = {
        surname: this.surname,
        name: this.name,
        fathername: this.fathername,
        mobile: this.mobile,
        email: this.email,
        addressline1: this.addressline1,
        addressline1: this.addressline2,
        addressline1: this.addressline3,
        area: this.area.PostOfficeName,
        state: this.state.State,
        city: this.city.City,
        pincode: this.pincode,
        gender: this.gender,
        aadharcardno: this.aadharcardno,
        profile_image: this.profile_image,
        data: this.data,
        aadharfrontside: this.documents.aadharfrontside,
        aadharbackside: this.documents.aadharbackside,
        pancard: this.documents.pancard,
        panno: this.panno,
      };
      // va: this =,this.$route.params.id ? true : false;
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          this.submitDisable = true;
          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: `${this.ifEdit ? "put" : "post"}`,
            url: this.ifEdit
              ? `${baseApi}/farmer/${this.$route.params.id}`
              : `${baseApi}/farmer`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((json) => {
              this.submitDisable = false;

              this.$swal({
                title: "Submited",
                text: json.data.message
                  ? `${json.data.message}`
                  : json.data.success
                  ? `${json.data.success}`
                  : "Update SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.$router.push("/master/accounting/farmer");
            })
            .catch((error) => {
              this.submitDisable = false;

              this.$swal({
                title: "Error!",
                text: `${error}`,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },

    onClickBack() {
      this.$router.push("/master/accounting/farmer");
    },
  },
};
</script>
